<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="connector.fields">
        <v-row justify="center">
          <v-col cols="12" lg="10">
            <v-subheader class="pa-0">{{ $tc('connector.title', 1) }}</v-subheader>
            <v-card :loading="microserviceIsLoading">
              <v-card-text>
                <MicroserviceAutocomplete v-model="values.microservice"
                                          field="microservice"
                                          @change="handleMicroserviceChange"/>
                <KTextField v-model="values.name" field="name" required/>

                <KCheckbox v-model="values.isActive" field="isActive"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-fade-transition>
          <v-row v-if="microservice" justify="center">
            <v-col cols="12" lg="6" sm="12">
              <v-subheader class="pa-0">{{ $t('connector.headers.actions') }}</v-subheader>
              <v-card>
                <v-card-text>
                  <div v-for="(actionObj) in microservice.actions" :key="actionObj.key">
                    <v-row>
                      <div class="col-6 pr-3">
                        <VCheckbox v-model="values.actions[actionObj.key]" :label="actionObj.key"/>
                      </div>
                      <MapperAutocomplete
                          v-model="values.mappers[actionObj.key]"
                          :action="actionObj.key"
                          :disabled="!values.actions[actionObj.key]"
                          grid="col-6 pr-3"
                          field="mapper"
                      />
                    </v-row>
                    <v-row class="pr-3 mt-n5">
                      <VSpacer/>
                      <v-btn text x-small v-if="actionObj.isCallable" @click="triggerAction(actionObj)">
                        {{ $t('connector.actions.trigger') }}
                      </v-btn>
                      <v-btn text
                             x-small
                             v-if="values.id && values.mappers[actionObj.key] && values.actions[actionObj.key]"
                             :href="`${translatorUrl}/connector/${values.id}/mapper/${values.mappers[actionObj.key]}`"
                             target="_blank"
                      >
                        {{ $t('connector.actions.changeVariables') }}
                      </v-btn>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>

              <v-subheader class="pa-0">{{ $t('connector.headers.test') }}</v-subheader>
              <v-card :loading="testConnectionIsLoading">
                <v-card-text>
                  <v-expand-transition>
                    <div v-if="testState">
                      <v-alert
                          :type="testState"
                          outlined
                          text
                      >
                        <template v-if="!testMessage">{{ $t(`connector.testConnection.${testState}`) }}</template>
                        <template v-else>
                          {{ testMessage }}
                          <v-btn :href="testButtonUrl" target="_blank" v-if="testButtonUrl" outlined :color="testState" class="ml-3">
                            {{ $t('connector.testConnection.button') }}
                          </v-btn>
                        </template>
                      </v-alert>
                    </div>
                  </v-expand-transition>
                  {{ $t('connector.testConnection.text') }}
                </v-card-text>
                <v-card-actions>
                  <VSpacer/>
                  <v-btn color="primary" outlined @click="handleTestConnection">
                    {{ $t('connector.actions.test') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" lg="4" sm="12">
              <v-subheader class="pa-0">{{ $t('connector.headers.settings') }}</v-subheader>
              <v-card>
                <v-card-text>
                  <DynamicInput v-for="setting in microservice.settings"
                                :key="setting.key"
                                v-model="values.settings[setting.key]"
                                :field="`settings.${setting.key}`"
                                :label="setting.label || setting.key"
                                :hint="setting.hint"
                                :options="setting.options"
                                :required="setting.isRequired && setting.type !== 'checkbox'"
                                :type="setting.type"/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-fade-transition>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import { show } from '@/api/endpoints/microservice.js';
import Connector from '@/application/models/connector.js';
import MicroserviceAutocomplete from '@/components/autocompletes/Microservice.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import Form from '@/components/VuetifyResourceBaseForm.vue';
import DynamicInput from '@/components/DynamicInput.vue';
import { test, triggerAction } from '@/api/endpoints/connector.js';
import MapperAutocomplete from '@/components/autocompletes/Mapper.vue';
import eventBus from '@/eventBus.js';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';

export default {
  name: 'ConnectorForm',
  extends: Form,
  components: {
    KCheckbox,
    MapperAutocomplete,
    DynamicInput,
    KTextField,
    MicroserviceAutocomplete,
    KFieldGroup,
  },
  data: () => ({
    values: new Connector(),
    microservice: null,
    microserviceIsLoading: false,
    testConnectionIsLoading: false,
    testState: '',
    testButtonUrl: '',
    testMessage: '',
  }),
  computed: {
    translatorUrl() {
      return process.env.VUE_APP_TRANSLATOR_URL;
    },
  },
  watch: {
    values: {
      deep: true,
      handler() {
        this.testState = '';
      },
    },
  },
  created() {
    this.fetchMicroservice();
  },
  methods: {
    async fetchMicroservice() {
      if (!this.values.microservice) return;
      this.microserviceIsLoading = true;
      const response = await show(this.values.microservice.id);
      this.microservice = response.data.data;
      this.microserviceIsLoading = false;

      const setAllActionsToFalse = this.microservice.actions.reduce((accumulator, { key }) => ({
        ...accumulator,
        [key]: false,
      }), {});

      const setAllActionsToUndefined = this.microservice.actions.reduce((accumulator, { key }) => ({
        ...accumulator,
        [key]: undefined,
      }), {});
      this.values.actions = { ...setAllActionsToFalse, ...this.values.actions };
      this.values.mappers = { ...setAllActionsToUndefined, ...this.values.mappers };

      this.microservice.settings.forEach(({
        key,
        type,
      }) => {
        const { values: { settings } } = this;

        switch (type) {
          case 'select':
            settings[key] = settings[key] || [];
            break;
          case 'checkbox':
            settings[key] = settings[key] || false;
            break;
          default:
            settings[key] = settings[key] || '';
            break;
        }
      });
    },
    handleMicroserviceChange() {
      this.microservice = null;
      this.values = {
        ...this.values,
        settings: {},
        actions: {},
      };
      this.fetchMicroservice();
    },
    handleTestConnection() {
      this.testState = '';
      this.testConnectionIsLoading = true;
      test(this.values).then(() => {
        this.testState = 'success';
        this.testMessage = '';
      }).catch((error) => {
        if (error.response.status === 424) {
          this.testButtonUrl = error.response.data.data.url
          this.testMessage = error.response.data.data.message
        }
        this.testState = 'error';
      }).finally(() => {
        this.testConnectionIsLoading = false;
      });
    },
    async triggerAction(action) {
      try {
        await triggerAction(this.values.id, action.key);
        action.isCallable = false;
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('connector.messages.triggerComplete'),
        });
      } catch (e) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('connector.messages.triggerError'),
        });
      }
      //'connector/{id}/manual-action'
    },
  },
};
</script>
