import { get } from '@/api/implementation/app';
import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { convertSnakeToCamel } from '@/api/util/keyConverter.js';

export const autocomplete = (page, perPage, search, sortBy, descending, params) => {
  return getPaginated('microservice/autocomplete', page, perPage, search, sortBy, descending, params);
};

export const show = async (microserviceId) => {
  const response = await get(`microservice/${microserviceId}`);
  if (response.status !== 200) return response;
  response.data.data.actions = response.data.data.actions.map(action => ({
      ...action,
      key: convertSnakeToCamel(action.key),
    }),
  );
  response.data.data.settings = response.data.data.settings.map(setting => ({
      ...setting,
      key: convertSnakeToCamel(setting.key),
    }),
  );
  return response
};
