<template>
  <KAutocomplete v-bind="$attrs" v-on="$listeners" :items="mappers" item-text="name" item-value="id"/>
</template>

<script>
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import { autocomplete } from '@/api/endpoints/mapper.js';
import { convertCamelToSnake } from '@/api/util/keyConverter.js';

export default {
  name: 'MapperAutocomplete',
  components: { KAutocomplete },
  props: {
    action: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    mappers: [],
  }),
  async created() {
    const { data: { data } } = await autocomplete(convertCamelToSnake(this.action));
    this.mappers = data;
  },
};
</script>
